import React from "react";
import Add from "../assets/icons/plusadd.svg"
import AssetsNftCard from "../components/AssetsNftCard";
import {useNavigate} from "react-router-dom";


function Assets () {
    const navigate = useNavigate()

    const nftItems = [
        {
            active: true,
            src: "https://images.unsplash.com/photo-1528991435120-e73e05a58897?auto=format&fit=crop&q=80&w=2787&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            active: false,
            src: "https://images.unsplash.com/photo-1572495532056-8583af1cbae0?auto=format&fit=crop&q=80&w=2787&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            active: true,
            src: "https://images.unsplash.com/photo-1607823489283-1deb240f9e27?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjZ8fEFmcmljYW4lMjBmYXNoaW9ufGVufDB8fDB8fHww",
        },
    ];

    return (
        <div className="assets">
            <div className="assets-text-block">
                <p className="discover-text">
                    My Assets
                </p>
            </div>
            <div className="assets-group">
                <div className="add-nft-card">
                    <div className="add-nft-card_top">
                        <img src={Add} alt=""/>
                        <div className="add-nft-card_text">Add NFT</div>
                    </div>
                    <div className="add-nft-card_center">
                        Explore the world of digital art by listing your unique NFT for sale!
                    </div>
                    <div className="add-nft-card_button" onClick={() => navigate("./sale")}>
                        List for sale
                    </div>
                </div>
                {nftItems.map((el:any,index) => <AssetsNftCard
                    key={index}
                    image={el.src}
                    name="Name"
                    owners="owners"
                    ownwersCount={274}
                    percent={41}
                    houre={24}
                    price={1.56}
                    statusView
                    active={el.active}
                />)}
            </div>
        </div>
    )
};

export default Assets;