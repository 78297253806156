import React, {useEffect, useState} from "react";
import Logo from "../assets/logo.svg";
import { navItems } from "../constants/navItems";
import { socialItems } from "../constants/socialItems";
import leftFill from "../assets/icons/left-fill.svg";
import close from "../assets/icons/close.svg";
import { useNavigate } from "react-router-dom";
import InlineSVG from 'react-inlinesvg';
import avatar from "../assets/icons/avatar.svg";
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {Dropdown, Modal, Popover, Select} from 'antd';
import Profile from "../assets/logindropdownicons/profile.svg";
import Wallet from "../assets/logindropdownicons/wallet.svg";
import Watchlist from "../assets/logindropdownicons/watchlist.svg";
import Details from "../assets/logindropdownicons/details.svg";
import Assets from "../assets/logindropdownicons/assets.svg";
import Staking from "../assets/logindropdownicons/staking.svg";
import Info from "../assets/logindropdownicons/info.svg";
import Settings from "../assets/logindropdownicons/settings.svg";
import Logout from "../assets/logindropdownicons/logout.svg";
import UitWallet from "../assets/icons/uit_wallet.svg";
import CloseIcon from "../assets/icons/close.svg";
import ConvertIcon from "../assets/icons/convert-icon.svg";
import ArrowUpRed from "../assets/icons/arrow-up-red.svg";
import ArrowDownRed from "../assets/icons/arrow-down-red.svg";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import ConnectButton from "../button/ConnectButton";
import ReferralIcon from "../assets/icons/referral.svg";
import NotificationIcon from "../assets/icons/notification-bell.svg";
import NFT from "../assets/nft/nft_6.svg";

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Space } from 'antd';

// 1. Get projectId
const projectId = '3198f90121e49a902bfb2f2d8976ea0d'

// 2. Set chains
const mainnet = {
    chainId: 97,
    name: 'BNB Smart Chain Testnet',
    currency: 'tBNB',
    explorerUrl: 'https://bscscan.io',
    rpcUrl: 'https://data-seed-prebsc-1-s2.bnbchain.org:8545'
}

// 3. Create a metadata object
const metadata = {
    name: 'RedCost',
    description: 'My Website description',
    url: 'https://avanesovcrypto.xyz', // origin must match your domain & subdomain
    icons: [avatar]
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 97 // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    themeMode: "light",
    themeVariables: {
        "--w3m-font-family": 'Montserrat-Bold',
        "--w3m-accent": "#FFFFFF",
        "--w3m-border-radius-master": "40px"	
    }
})



function WebHeader({address, chainId, isConnected, open}: any) {
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [convertModalOpen, setConvertModalOpen] = useState(false);
    const [nftDropdownOpen, setNftDropdownOpen] = useState<any>();
    const [mainContent, setMainContent] = useState<any>();
    const [statusData, setStatusData] = useState([]);

    const navigate = useNavigate();

    console.log("address",address)
    console.log("chainId",chainId)
    console.log("isConnected",isConnected)

    const openSocialGroup = () => {
        setIsOpen(!isOpen);
    };

    const handleClickNavItem = (index: any) => {
        setActiveIndex(index)

        switch (index) {
            case 0:
                navigate("./about")
                break;
            case 1:
                navigate("./discover")
                break;
            case 2:
                navigate("./airdrop")
                break;
            case 3:
                navigate("./investors")
                break;
            case 4:
                navigate("./contacts")
                break;

            default:
                navigate("./error")
        }
        console.log(`Click on ${index} and navigate ${index}`)
    }
    const handleUsersMarket = () => {
        setActiveIndex(null)
        navigate("./market")
    };
    const handleRedCostMarket = () => {
        setActiveIndex(null)
        navigate("./collection")
    };

    const handleClickSocialItem = (item:String) => {
        switch (item) {
            case "x":
                window.open("https://x.com/", '_blank');
                break;
            case "discord":
                window.open("https://discord.com/", '_blank');
                break;
            case "facebook":
                window.open("https://facebook.com/", '_blank');
                break;
            case "youtube":
                window.open("https://youtube.com/", '_blank');
                break;
            case "linkedin":
                window.open("https://linkedin.com/", '_blank');
                break;
            case "reddit":
                window.open("https://reddit.com/", '_blank');
                break;
            case "medium":
                window.open("https://medium.com/", '_blank');
                break;
            case "instagram":
                window.open("https://instagram.com/", '_blank');
                break;
            case "telegram":
                window.open("https://telegram.org/", '_blank');
                break;
            case "threads":
                window.open("https://www.threads.net/", '_blank');
                break;
            default:
                console.log(`Click on ${item} and navigate ${item}`)
        }

    }

    const handleMouseMove = (e: any) => {
        const boundingRect = e.currentTarget.getBoundingClientRect();
        const mouseX = e.clientX - boundingRect.left;
        const mouseY = e.clientY - boundingRect.top;
        const centerX = boundingRect.width / 8;
        const centerY = boundingRect.height / 2;
        const maxRotateX = 15; // Maximum rotation angle around X-axis
        const maxRotateY = 1; // Maximum rotation angle around Y-axis
        const perspective = 9000; // Perspective value

        const rotateX = ((mouseY - centerY) / centerY) * maxRotateX;
        const rotateY = ((mouseX - centerX) / centerX) * maxRotateY;

        e.currentTarget.style.transform = `perspective(${perspective}px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    const handleMouseLeave = (e: any) => {
        e.currentTarget.style.transform = 'perspective(300px) rotateX(0deg) rotateY(0deg)';
    };

    useEffect(() => {
        openMainContent()
    },[])

    function openMainContent () {
        let data:any = [
            { icon: NFT,name: 'Jon111111', price: '0.000 ETH', period: 'text', category: 'Notification Bell SVG vector file in monocolor and multicolor type for Sketch and Figma from Notification Bell Vectors svg vector collection', status: true,id: 1 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Vector file in monocolor and multicolor type for Sketch and Figma from Notification Bell Vectors svg vector collection', status: true,id: 2 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'NSketch and Figma from Notification Bell Vectors svg vector collection', status: true, id: 3 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Coming', status: false,id: 4 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Memberships', status: false, id: 5 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'PFPs', status: false,id: 6 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: false, id: 7 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Music', status: false,id: 8 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: false, id: 9 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Music', status: false,id: 10 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: false, id: 11 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Art', status: false,id: 12 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 13 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Art', status: false,id: 14 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 15 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'game', status: false,id: 16 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 17 },
            { icon: NFT,name: 'Mike555555', price: '0.000 ETH', period: 'text', category: 'game', status: false,id: 18 },
        ];
        const filteredData = data.filter((obj:any) => obj.status === true)
        setStatusData(filteredData);
        const content = (
            <div>
                <div onClick={()=> {
                    navigate("./")
                }} className="connect-icon">
                    <img src={Profile} alt=""/>
                    <span>Profile</span>
                </div>
                <div onClick={openWalletContent} className="connect-icon">
                    <img src={Wallet} alt=""/>
                    <span>Wallet</span>
                </div>
                <div onClick={()=> {
                    navigate("./")
                }} className="connect-icon">
                    <img src={Watchlist} alt=""/>
                    <span>Watchlist</span>
                </div>
                <div onClick={()=> {
                    navigate("./")
                }} className="connect-icon">
                    <img src={Details} alt=""/>
                    <span>Details</span>
                </div>
                <div onClick={()=> {
                    navigate("./assets")
                }} className="connect-icon">
                    <img src={Assets} alt=""/>
                    <span>My assets</span>
                </div>
                <div onClick={()=> {
                    navigate("./staking")
                }} className="connect-icon">
                    <img src={Staking} alt=""/>
                    <span>Staking</span>
                </div>
                <div onClick={()=> {
                    navigate("./info")
                }} className="connect-icon">
                    <img src={Info} alt=""/>
                    <span>Info</span>
                </div>
                <div onClick={openReferralContent} className="connect-icon">
                    <img src={ReferralIcon} alt=""/>
                    <span>Referral</span>
                </div>
                <div onClick={openNotificationContent} className="connect-icon">
                    <Badge count={statusData.length}>
                        <img src={NotificationIcon} alt=""/>
                    </Badge>
                    <span>
                        Notification
                    </span>
                </div>
                <div onClick={()=> {
                    navigate("./")
                }} className="connect-icon">
                    <img src={Settings} alt=""/>
                    <span>Settings</span>
                </div>
                <div onClick={()=> open()} className="connect-icon">
                    <img src={Logout} alt=""/>
                    <span>Logout</span>
                </div>
            </div>
        );
        setMainContent(content)
    }


    function openWalletContent ()  {
        let data:any = [
            { icon: NFT,name: 'Jon111111', price: '0.000 ETH', period: 'text', category: 'Music', status: false,id: 1 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Game', status: false,id: 2 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Art', status: false, id: 3 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Coming', status: true,id: 4 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Memberships', status: false, id: 5 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'PFPs', status: false,id: 6 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: false, id: 7 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Music', status: true,id: 8 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: true, id: 9 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Music', status: false,id: 10 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: false, id: 11 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Art', status: false,id: 12 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 13 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Art', status: false,id: 14 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 15 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'game', status: false,id: 16 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 17 },
            { icon: NFT,name: 'Mike555555', price: '0.000 ETH', period: 'text', category: 'game', status: false,id: 18 },
        ];

        const walletContent =
            <div style={{paddingLeft: "10px", paddingRight: "10px"}}>
                <div className="new-content-header">
                    <div className="new-content-header_left">
                        <img src={UitWallet} alt=""/>
                        <span>Wallet</span>
                    </div>
                    <img src={CloseIcon} alt="" className="new-content-header_right" onClick={openMainContent}/>
                </div>
                <div className="new-content-body" style={{maxHeight: "350px", overflowY: "scroll"}}>
                    {data.map((el:any) =>{
                        return (
                        <div className="new-content-body_item">
                            <span>ETH</span>
                            <span>0.000</span>
                        </div> )
                    })
                    }
                </div>
                <div className="new-content-button" onClick={() => setConvertModalOpen(true)}>
                    Convert
                </div>
            </div>
       setMainContent(walletContent)
    };


    function openReferralContent ()  {
        let data:any = [
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5jj4r", price: '0.001', category: 'Music', status: false,id: 1 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j874", price: '0.002', category: 'Game', status: false,id: 2 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi58742", price: '0.003', category: 'Art', status: false, id: 3 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi989f7", price: '0.004', category: 'Coming', status: true,id: 4 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5jj4r", price: '0.007', category: 'Memberships', status: false, id: 5 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5jj4r", price: '0.009', category: 'PFPs', status: false,id: 6 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j21l", price: '0.015', category: 'Music', status: false, id: 7 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j21e", price: '0.2', category: 'Music', status: true,id: 8 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j21r", price: '0.008', category: 'Music', status: true, id: 9 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j891", price: '0.1', category: 'Music', status: false,id: 10 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j21t", price: '0.007', category: 'Music', status: false, id: 11 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5jop", price: '0.008', category: 'Art', status: false,id: 12 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5jlk", price: '0.05', category: 'music', status: false, id: 13 },
        ]

        const referralContent =
            <div style={{paddingLeft: "10px", paddingRight: "10px", paddingBottom: "20px", overflow: "hidden"}}>
                <div className="new-content-header">
                    <div className="new-content-header_left">
                        <img src={ReferralIcon} alt=""/>
                        <span>Referral</span>
                    </div>
                    <img src={CloseIcon} alt="" className="new-content-header_right" onClick={openMainContent}/>
                </div>
                <div className="new-content-body" style={{maxHeight: "350px", overflowY: "scroll"}}>
                    {data.map((el:any)=>{
                        return (
                            <div className="new-content-body_item">
                                <span>{el.walletAddress.slice(0,3) + "..." + el.walletAddress.slice(-2)}</span>
                                <span>{el.price} BNB</span>
                            </div>
                        )
                    })}
                </div>
            </div>
       setMainContent(referralContent)
    };

    function openNotificationContent  ()  {

        const notificationContent =
            <div style={{paddingLeft: "10px", paddingRight: "10px", paddingBottom: "20px", overflow: "hidden"}}>
                <div className="new-content-header">
                    <div className="new-content-header_left">
                        <img src={NotificationIcon} alt=""/>
                        <span>Notification</span>
                    </div>
                    <img src={CloseIcon} alt="" className="new-content-header_right" onClick={openMainContent}/>
                </div>
                <div className="new-content-body" style={{maxHeight: "350px", overflowY: "scroll"}}>
                    {statusData.map((el:any)=>{
                        return (
                            <div className="new-content-body_item">
                                <span>
                                    Notification {el.id}
                                    <br/>
                                    <hr/>
                                    <br/>
                                    {el.category}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        if (statusData.length > 0) {
            setMainContent(notificationContent)
        }
    };

    const handleConvert = () => {
        console.log("Convert")
    };
    const handleExchange = () => {
        setConvertModalOpen(false)
        console.log("Exchange")
    };
    const handleOpenNftDropdown = (e:any) => {
        setNftDropdownOpen(e)
    };


    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <span className="market-nft-dropdown-items" rel="noopener noreferrer" onClick={handleUsersMarket}>
                    Auction Market
                </span>
            ),
        },
        {
            key: '2',
            label: (
                <span className="market-nft-dropdown-items" rel="noopener noreferrer" onClick={handleRedCostMarket}>
                    RedCost Collection
                </span>
            ),
        },
    ];


    return (
        <div className="web-header">
            <div className="logo"
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onClick={() => { navigate("./");
                setActiveIndex(null)
                }}
            >
                <img src={Logo} alt="logo" />
            </div>
            <div className={!isOpen ? "navbar" : "navbar hide-nav"}>
                {navItems.map((item: String, index: any) => {
                    return <div className="navbar-item"
                                key={index}
                                onClick={() => handleClickNavItem(index)}
                    >
                       <span className={activeIndex === index ? "navbar-item-is-active" : ""}>{item}</span>
                    </div>
                })}

            </div>
            <div className={!isOpen ? "navbar-item-market_nft" : "navbar-item-market_nft hide-nav"} >
                <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                    overlayClassName="nft-dropdown"
                    onOpenChange={handleOpenNftDropdown}
                    placement="bottomRight"
                >
                    <a onClick={(e) => e.preventDefault()} >
                        <Space>
                            <span className="market-text">Market NFT</span>
                            <img src={ArrowUpRed} alt="up" className={nftDropdownOpen ? "rotate-arrow-transition": "rotate-arrow-transition rotate-arrow"}/>
                        </Space>
                    </a>
                </Dropdown>
            </div>



            <div className="web-header-group-items">
                <div className={isConnected? "login-connect": "login"}>

                    {!isOpen ?
                        <img src={leftFill} alt="leftFill" className="social-group-logo-img" onClick={openSocialGroup} /> :
                        <img src={close} alt="leftFill" className="social-group-logo-img" onClick={openSocialGroup} />
                    }
                    <div className={!isOpen ? "social-group-logo" : "social-group-logo open-social-items"}>
                        {socialItems.map((socialItem: any, index) => {
                            return <div
                                key={index}
                                onClick={() => handleClickSocialItem(socialItem.name)}
                            >
                                <div className="svg-container">
                                    {socialItem.logosvg}
                                    {socialItem.logosvgred}
                                </div>
                            </div>
                        })}
                    </div>
                    {/*{isConnected ?*/}
                    {/*    <div onClick={() => console.log("show dropdown")} className="login-button-connected-web">*/}
                    {/*        <img src={avatar} alt="avatar" style={{ cursor: "pointer" }} />*/}
                    {/*    </div> :*/}
                    {/*    <ConnectButton/>*/}
                    {/*    // <w3m-button balance="hide" size="sm" loadingLabel="wait..."/>*/}
                    {/*    // <div className="login-button" ></div>*/}
                    {/*}*/}
                    {isConnected ?
                        <Popover
                            content={mainContent}
                            placement="bottomRight"
                            className="header-dropdown"
                            trigger="click"
                            onOpenChange={openMainContent}
                        >
                            <img src={avatar} alt="avatar" style={{ cursor: "pointer" }} />
                        </Popover> :
                        <button className="login-button" onClick={() => open()}>Login</button>
                        // <w3m-button balance="hide" size="sm" loadingLabel="wait..."/>
                        // <div className="login-button" ></div>
                    }


                </div>
            </div>

            <Modal
                centered
                width={609}
                open={convertModalOpen}
                onCancel={() => setConvertModalOpen(false)}
                footer={null}
                className="approve-popup"
            >
                <div className="approve-listing-modal-title">Convert XXX to XXX</div>

                <div className="convert-listing-modal-body">
                    <div className="convert-input-group">
                        <div className="set-price-group">
                            <input type="text" className="set-price-input-convert" placeholder="0.0000"/>
                            <Select
                                onChange={()=>{}}
                                className="set-price-input-select"
                                defaultValue="BNB"
                                options={[
                                    { value: 'BNB', label: 'BNB' },
                                    { value: 'Ch1', label: 'Ch1' },
                                    { value: 'Ch2', label: 'Ch2' },
                                ]}
                            />
                        </div>
                        <div className="convert-balance-info">Balance 0.000 XXX</div>
                    </div>

                    <div className="status-icon margin-top-icon" onClick={handleConvert} >
                        <img src={ConvertIcon} alt="" />
                    </div>
                    <div className="convert-input-group">
                        <div className="set-price-group">
                            <input type="text" className="set-price-input-convert" placeholder="0.0000"/>
                            <Select
                                onChange={()=>{}}
                                className="set-price-input-select"
                                defaultValue="BNB"
                                options={[
                                    { value: 'BNB', label: 'BNB' },
                                    { value: 'Ch1', label: 'Ch1' },
                                    { value: 'Ch2', label: 'Ch2' },
                                ]}
                            />
                        </div>
                        <div className="convert-balance-info">Balance 0.000 XXX</div>
                    </div>
                </div>

                <div className="convert-listing-modal-buttons">
                    <button className="convert-listing-modal-buttons_convert" onClick={handleExchange}>Approve</button>
                </div>
            </Modal>
        </div>
    )
}

export default WebHeader;