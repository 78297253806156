import React, {useEffect, useState} from 'react';
import ModerationIcon from "../assets/icons/moderation.svg"
import {marketButtons} from "../constants/marketButtonsName";
import NFT from "../assets/nft/nft_6.svg"
function Moderation () {
    const [data, setData] = useState<any>([]);
    const [name, setName] = useState("All");
    const [moderationCategory, setModerationCategory] = useState("");
    const [moderationIndex, setModerationIndex] = useState();

    useEffect(() => {
        setData([
            { icon: NFT,name: 'Jon111111', price: '0.000 ETH', period: 'text', category: 'Music', status: false,id: 1 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Game', status: false,id: 2 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Art', status: false, id: 3 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Coming', status: true,id: 4 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Memberships', status: false, id: 5 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'PFPs', status: false,id: 6 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: false, id: 7 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Music', status: true,id: 8 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: true, id: 9 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Music', status: false,id: 10 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: false, id: 11 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Art', status: false,id: 12 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 13 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Art', status: false,id: 14 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 15 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'game', status: false,id: 16 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 17 },
            { icon: NFT,name: 'Mike555555', price: '0.000 ETH', period: 'text', category: 'game', status: false,id: 18 },
        ]);
    },[])



    const handleDelete = (idx:any) => {
        const deletedData = data.filter((obj:any,i:any)=> i !== idx)
        console.log("newDataaaa=>>>>",deletedData)
        setData(deletedData)
    };

    // const handleFilter = (ev:any) => {
    //     let val = ev.target.value;
    //     setName(val)
    //     if (val === "All"){
    //         setData(data)
    //     } else{
    //         const filteredData = data.filter((obj:any)=> obj.category === val)
    //         setData(filteredData)
    //     }
    // };

    const handleInputModerationCategory = (event:any,index:any) => {
        console.log("new name",event.target.value);
        console.log("index",index);
        setModerationCategory(event.target.value);
        setModerationIndex(index);
    }


    const handleAcceptModeration = (index:any,item:any) => {
        handleDelete(index)
        // setModerationIndex(index);
        // item.status = true
        // if (index === moderationIndex && moderationCategory.length > 0) {
        //     console.log("Accept Moderation in this Category ===>>>", moderationCategory, "INDEX ====>>>>", moderationIndex, "item", item);
        //     item.category = moderationCategory;
        //     console.log("new ITEMMMM", item,"INDEEEEEXXXX",index)
        // }
        //     else {
        //     console.log("defaultCategory",item, index)
        // }
    };

    const handleDenyModeration = (index:any) => {
        handleDelete(index)
        console.log("Deny Moderation",index)
    };

    return (
        <div className="moderation-scroll">
            <div className="moderation-main">
            <div className="moderation-main_title">
                <img src={ModerationIcon} alt=""/>
                <span>Moderation</span>
            </div>
            {/*<div className="moderation-main_buttons-group">*/}
            {/*    {marketButtons.map((btn,index)=>{*/}
            {/*        return (*/}
            {/*            <button*/}
            {/*                key={index}*/}
            {/*                className={btn.text === name? "table-button-active table-button": "table-button"}*/}
            {/*                onClick={handleFilter}*/}
            {/*                value={btn.text}*/}
            {/*            >*/}
            {/*              {btn.text}*/}
            {/*            </button>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</div>*/}
            {data.length? <div className="table-container">
                <div className="table-container_header">
                        <span></span>
                        <span>Name</span>
                        <span>Price</span>
                        <span>Period</span>
                        <span>Author</span>
                        <span style={{justifyContent:"center"}}>Status</span>
                </div>
                <div className="custom-table">
                    {data.map((item:any, index:any) => (
                        <div key={index} className="table-container_item">
                            <span>
                                <img src={item.icon} alt="icon" className="table-icon"/>
                            </span>
                            <span>{item.name}</span>
                            <span style={{fontWeight: "600"}}>{item.price}</span>
                            <span>{item.period}</span>
                            <span>
                                <input type="text" placeholder={item.category} className="moderation-table-input" onInput={(ev)=>handleInputModerationCategory(ev,index)}/>
                            </span>
                            <span style={{justifyContent:"center",gap: "12px"}}>
                                <div className={!item.status ? "status-icon" : "status-icon-active"} onClick={()=>handleAcceptModeration(index,item)}>
                                     <svg width="15" height="12" viewBox="0 0 15 12" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1767_4937)">
                                            <path
                                                d="M13.2909 0.795898L14.1315 1.6509L4.82289 11.2055L0.251953 6.51728L1.0925 5.65515L4.82289 9.48129L13.2909 0.795898Z"
                                                fill="#949494"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1767_4937">
                                                <rect width="13.8795" height="10.4096" fill="white"
                                                      transform="translate(0.251953 0.795898)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="status-icon" onClick={()=>handleDenyModeration(index)}>
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1767_5447)">
                                        <path d="M6.07957 5.19271L10.2057 1.06654L9.31986 0.180664L5.19369 4.30683L1.06752 0.180664L0.181641 1.06654L4.30781 5.19271L0.181641 9.31888L1.06752 10.2048L5.19369 6.07859L9.31986 10.2048L10.2057 9.31888L6.07957 5.19271Z" fill="#949494"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_1767_5447">
                                        <rect width="10.0241" height="10.0241" fill="white" transform="translate(0.181641 0.180664)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </span>
                        </div>
                    ))}
                </div>
            </div>:
                <div className="table-container empty-table">
                    no data
                </div>
            }


        </div>
        </div>
    );
};

export default Moderation;
