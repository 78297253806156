import React, {useState} from "react";
import {Badge, Drawer, Dropdown, MenuProps, Modal, Select, Space} from 'antd';
import {navItems, profileItem} from "../constants/navItems";
import {socialItems} from "../constants/socialItems";
import mobileLogo from "../assets/mobile-logo.svg"
import avatar from "../assets/icons/avatar.svg";
import {useNavigate} from "react-router-dom";
import ArrowUpRed from "../assets/icons/arrow-up-red.svg";
import Profile from "../assets/logindropdownicons/profile.svg";
import Wallet from "../assets/logindropdownicons/wallet.svg";
import Watchlist from "../assets/logindropdownicons/watchlist.svg";
import Details from "../assets/logindropdownicons/details.svg";
import Assets from "../assets/logindropdownicons/assets.svg";
import Staking from "../assets/logindropdownicons/staking.svg";
import Info from "../assets/logindropdownicons/info.svg";
import ReferralIcon from "../assets/icons/referral.svg";
import NotificationIcon from "../assets/icons/notification-bell.svg";
import Settings from "../assets/logindropdownicons/settings.svg";
import Logout from "../assets/logindropdownicons/logout.svg";
import NFT from "../assets/nft/nft_6.svg";
import UitWallet from "../assets/icons/uit_wallet.svg";
import CloseIcon from "../assets/icons/close.svg";
import ConvertIcon from "../assets/icons/convert-icon.svg";

function MobileHeader({address, chainId, isConnected, open}: any) {

    const [menuOpen, setMenuOpen] = useState(false);
    const [burgerDrawer, setBurgerDrawer] = useState(false);
    const [nftDropdownOpen, setNftDropdownOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);

    const [convertModalOpen, setConvertModalOpen] = useState(false);
    const [isMainContentOpen, setIsMainContentOpen] = useState(true);
    const [walletConnectData, setWalletConnectData] = useState<any>();
    const [isWalletContentOpen, setIsWalletContentOpen] = useState(false);
    const [isReferralContentOpen, setIsReferralContentOpen] = useState(false);
    const [referralConnectData, setReferralConnectData] = useState<any>();
    const [isNotificationContentOpen, setIsNotificationContentOpen] = useState(false);
    const [notificationConnectData, setNotificationConnectData] = useState<any>([]);

    const navigate = useNavigate()

    const showProfileDrawer = () => {
        let data:any = [
            { icon: NFT,name: 'Jon111111', price: '0.000 ETH', period: 'text', category: 'Notification Bell SVG vector file in monocolor and multicolor type for Sketch and Figma from Notification Bell Vectors svg vector collection', status: true,id: 1 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Vector file in monocolor and multicolor type for Sketch and Figma from Notification Bell Vectors svg vector collection', status: true,id: 2 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'NSketch and Figma from Notification Bell Vectors svg vector collection', status: true, id: 3 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Coming', status: false,id: 4 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Memberships', status: false, id: 5 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'PFPs', status: false,id: 6 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: false, id: 7 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Music', status: false,id: 8 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: false, id: 9 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Music', status: false,id: 10 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Music', status: false, id: 11 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Art', status: false,id: 12 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 13 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Art', status: false,id: 14 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 15 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'game', status: false,id: 16 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'music', status: false, id: 17 },
            { icon: NFT,name: 'Mike555555', price: '0.000 ETH', period: 'text', category: 'game', status: false,id: 18 },
        ];
        const filteredData = data.filter((obj:any) => obj.status === true);

        setNotificationConnectData(filteredData)
        setMenuOpen(true);
        setBurgerDrawer(false)
    };

    const showBurgerDrawer = () => {
        if (menuOpen) {
            setMenuOpen(false);
            setIsMainContentOpen(true);
            setIsWalletContentOpen(false);
            setIsReferralContentOpen(false);
            setIsNotificationContentOpen(false)
        } else {
            setMenuOpen(true)
            setBurgerDrawer(true)
        }
    };

    const onClose = () => {
        setMenuOpen(false);
    };


    const handleClickSocialItem = (item:String) => {
        switch (item) {
            case "x":
                window.open("https://x.com/", '_blank');
                break;
            case "discord":
                window.open("https://discord.com/", '_blank');
                break;
            case "facebook":
                window.open("https://facebook.com/", '_blank');
                break;
            case "youtube":
                window.open("https://youtube.com/", '_blank');
                break;
            case "linkedin":
                window.open("https://linkedin.com/", '_blank');
                break;
            case "reddit":
                window.open("https://reddit.com/", '_blank');
                break;
            case "medium":
                window.open("https://medium.com/", '_blank');
                break;
            case "instagram":
                window.open("https://instagram.com/", '_blank');
                break;
            case "telegram":
                window.open("https://telegram.org/", '_blank');
                break;
            case "threads":
                window.open("https://www.threads.net/", '_blank');
                break;
            default:
                console.log(`Click on ${item} and navigate ${item}`)
        }
    }

    const handleHome = () => {
        navigate("./")
        setMenuOpen(false)
    };
    const handleOpenNftDropdown = () => {
        setNftDropdownOpen(!nftDropdownOpen)
    };

    const handleUsersMarket = () => {
        setActiveIndex(null)
        setMenuOpen(false);
        setNftDropdownOpen(false)
        navigate("./market")
    };
    const handleRedCostMarket = () => {
        setActiveIndex(null);
        setMenuOpen(false);
        setNftDropdownOpen(false)
        navigate("./collection")
    };
    const handleClickNavItem = (index: any) => {
        switch (index) {
            case 0:
                navigate("./about");
                setMenuOpen(false)
                break;
            case 1:
                navigate("./discover");
                setMenuOpen(false)
                break;
            case 2:
                navigate("./airdrop")
                setMenuOpen(false)
                break;
            case 3:
                navigate("./investors");
                setMenuOpen(false)
                break;
            case 4:
                navigate("./contacts");
                setMenuOpen(false)
                break;
            default:
                navigate("./error")
        }
        console.log(`Click on ${index} and navigate ${index}`)
    }

    const HandleConvertPopupOpen = () => {
        setConvertModalOpen(true);
        onClose();
        setIsMainContentOpen(true);
        setIsWalletContentOpen(false);
        setIsReferralContentOpen(false);
        setIsNotificationContentOpen(false)
    };
    const handleConvert = () => {
        console.log("Convert")
    };
    const handleExchange = () => {
        setConvertModalOpen(false)
        console.log("Exchange")
    };

    function openWalletContent ()  {
        let data:any = [
            { icon: NFT,name: 'Jon111111', price: '0.000 ETH', period: 'text', category: 'Music', status: false,id: 1 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Game', status: false,id: 2 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Art', status: false, id: 3 },
            { icon: NFT,name: 'Mike', price: '0.000 ETH', period: 'text', category: 'Coming', status: true,id: 4 },
            { icon: NFT,name: 'Rob', price: '0.000 ETH', period: 'text', category: 'Memberships', status: false, id: 5 },
        ];
        setWalletConnectData(data)
        setIsWalletContentOpen(true)
        setIsMainContentOpen(false);
    }

    function openReferralContent ()  {
        let data:any = [
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5jj4r", price: '0.001', category: 'Music', status: false,id: 1 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j874", price: '0.002', category: 'Game', status: false,id: 2 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi58742", price: '0.003', category: 'Art', status: false, id: 3 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi989f7", price: '0.004', category: 'Coming', status: true,id: 4 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5jj4r", price: '0.007', category: 'Memberships', status: false, id: 5 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5jj4r", price: '0.009', category: 'PFPs', status: false,id: 6 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j21l", price: '0.015', category: 'Music', status: false, id: 7 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j21e", price: '0.2', category: 'Music', status: true,id: 8 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j21r", price: '0.008', category: 'Music', status: true, id: 9 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j891", price: '0.1', category: 'Music', status: false,id: 10 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5j21t", price: '0.007', category: 'Music', status: false, id: 11 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5jop", price: '0.008', category: 'Art', status: false,id: 12 },
            { icon: NFT, walletAddress: "0xLbz7JHiBTspS962RLKV8GndWFwi5jlk", price: '0.05', category: 'music', status: false, id: 13 },
        ]
        setReferralConnectData(data)
        setIsReferralContentOpen(true)
        setIsMainContentOpen(false);
    }

    function openNotificationContent ()  {
        setIsNotificationContentOpen(true)
        setIsMainContentOpen(false);
    }

    function openMainContent ()  {
        setIsMainContentOpen(true);
        setIsWalletContentOpen(false);
        setIsReferralContentOpen(false);
        setIsNotificationContentOpen(false)
    }

    return (
        <div className="mobile-header">
            <div onClick={handleHome} style={{cursor: "pointer"}} className="logo">
                <img src={mobileLogo} alt="mobileLogo"/>
            </div>
            <div className="login-burger-group">
                {isConnected ?
                    <div onClick={showProfileDrawer}>
                        <img src={avatar} alt="avatar" style={{cursor: "pointer"}}/>
                    </div> :
                    <div className="mobile-login-button" onClick={() => open()}>Login</div>
                }
                <div className={!menuOpen ? "hamburger" : "is-active"} onClick={showBurgerDrawer} id="hamburger-1">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
            <Drawer
                placement="right"
                onClose={onClose}
                open={menuOpen}
                width={390}
            >
                {burgerDrawer ?
                    <div className="drower-content-navbar">
                        {navItems.map((item: String, index: any) => {
                            return <div className="drower-content-navbar-item" key={index}
                                        onClick={() => handleClickNavItem(index)}>
                                <span className={activeIndex === index ? "navbar-item-is-active" : ""}>{item}</span>
                            </div>
                        })}
                        <div className="drawer-content-navbar-nft" onClick={handleOpenNftDropdown}>
                            Market NFT
                            <img src={ArrowUpRed} alt="up"
                                 className={nftDropdownOpen ? "rotate-arrow-transition" : "rotate-arrow-transition rotate-arrow"}/>
                        </div>
                        <div className={!nftDropdownOpen ? "d-none" : "dropdown-routes"}>
                            <span className="market-nft-dropdown-items" rel="noopener noreferrer"
                                  onClick={handleUsersMarket}>
                                Auction Market
                            </span>
                            <span className="market-nft-dropdown-items" rel="noopener noreferrer"
                                  onClick={handleRedCostMarket}>
                                RedCost Collection
                            </span>
                        </div>
                    </div> :
                    isMainContentOpen ? <div className="drower-content-navbar drower-content-profile">
                        <div onClick={() => {
                            navigate("./"); onClose()
                        }} className="connect-icon">
                            <img src={Profile} alt=""/>
                            <span>Profile</span>
                        </div>
                        <div className="connect-icon" onClick={openWalletContent}>
                            <img src={Wallet} alt=""/>
                            <span>Wallet</span>
                        </div>
                        <div onClick={() => {
                            navigate("./"); onClose()
                        }} className="connect-icon">
                            <img src={Watchlist} alt=""/>
                            <span>Watchlist</span>
                        </div>
                        <div onClick={() => {
                            navigate("./"); onClose()
                        }} className="connect-icon">
                            <img src={Details} alt=""/>
                            <span>Details</span>
                        </div>
                        <div onClick={() => {
                            navigate("./assets"); onClose()
                        }} className="connect-icon">
                            <img src={Assets} alt=""/>
                            <span>My assets</span>
                        </div>
                        <div onClick={() => {
                            navigate("./staking"); onClose()
                        }} className="connect-icon">
                            <img src={Staking} alt=""/>
                            <span>Staking</span>
                        </div>
                        <div onClick={() => {
                            navigate("./info"); onClose()
                        }} className="connect-icon">
                            <img src={Info} alt=""/>
                            <span>Info</span>
                        </div>
                        <div className="connect-icon" onClick={openReferralContent}>
                            <img src={ReferralIcon} alt=""/>
                            <span>Referral</span>
                        </div>
                        <div className="connect-icon" onClick={openNotificationContent}>
                            <Badge count={notificationConnectData?.length}>
                                <img src={NotificationIcon} alt=""/>
                            </Badge>
                            <span>
                                Notification
                            </span>
                        </div>
                        <div onClick={() => {
                            navigate("./"); onClose()
                        }} className="connect-icon">
                            <img src={Settings} alt=""/>
                            <span>Settings</span>
                        </div>
                        <div onClick={() => {
                            open(); onClose()
                        }} className="connect-icon">
                            <img src={Logout} alt=""/>
                            <span>Logout</span>
                        </div>
                    </div>:

                        isWalletContentOpen ? <div style={{paddingLeft: "10px", paddingRight: "10px"}}>
                            <div className="new-content-header">
                                <div className="new-content-header_left">
                                    <img src={UitWallet} alt=""/>
                                    <span>Wallet</span>
                                </div>
                                <img src={CloseIcon} alt="" className="new-content-header_right" onClick={openMainContent}/>
                            </div>
                            <div className="new-content-body">
                                {walletConnectData.map((el:any) =>{
                                    return (
                                        <div className="new-content-body_item">
                                            <span>ETH</span>
                                            <span>0.000</span>
                                        </div> )
                                })
                                }
                            </div>
                            <div className="new-content-button" onClick={HandleConvertPopupOpen}>
                                Convert
                            </div>
                        </div>:

                            isReferralContentOpen ? <div style={{paddingLeft: "10px", paddingRight: "10px", paddingBottom: "20px", overflow: "hidden"}}>
                            <div className="new-content-header">
                                <div className="new-content-header_left">
                                    <img src={ReferralIcon} alt=""/>
                                    <span>Referral</span>
                                </div>
                                <img src={CloseIcon} alt="" className="new-content-header_right" onClick={openMainContent}/>
                            </div>
                            <div className="new-content-body">
                                {referralConnectData.map((el:any)=>{
                                    return (
                                        <div className="new-content-body_item">
                                            <span>{el.walletAddress.slice(0,3) + "..." + el.walletAddress.slice(-2)}</span>
                                            <span>{el.price} BNB</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>:
                                isNotificationContentOpen ? <div style={{paddingLeft: "10px", paddingRight: "10px", paddingBottom: "20px", overflow: "hidden"}}>
                                    <div className="new-content-header">
                                        <div className="new-content-header_left">
                                            <img src={NotificationIcon} alt=""/>
                                            <span>Notification</span>
                                        </div>
                                        <img src={CloseIcon} alt="" className="new-content-header_right" onClick={openMainContent}/>
                                    </div>
                                    <div className="new-content-body">
                                        {notificationConnectData.map((el:any)=>{
                                            return (
                                                <div className="new-content-body_item">
                                                    <span>
                                                        Notification {el.id}
                                                        <br/>
                                                        <hr/>
                                                        <br/>
                                                        {el.category}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>: ""
                }

                <div className="drawer-content-navbar-bottom">
                    {socialItems.map((socialItem:any,index) =>{
                        return <div
                            key={index}
                            onClick={() => handleClickSocialItem(socialItem.name)}
                        >
                            <div className="svg-container">
                                <img src={socialItem.logo} alt="" className="image1"/>
                                <img src={socialItem.logored} alt="" className="image2"/>
                            </div>
                        </div>
                    })}
                </div>
            </Drawer>
            <Modal
                centered
                width={609}
                open={convertModalOpen}
                onCancel={() => setConvertModalOpen(false)}
                footer={null}
                className="approve-popup"
            >
                <div className="approve-listing-modal-title">Convert XXX to XXX</div>

                <div className="convert-listing-modal-body">
                    <div className="convert-input-group">
                        <div className="set-price-group">
                            <input type="text" className="set-price-input-convert" placeholder="0.0000"/>
                            <Select
                                onChange={()=>{}}
                                className="set-price-input-select"
                                defaultValue="BNB"
                                options={[
                                    { value: 'BNB', label: 'BNB' },
                                    { value: 'Ch1', label: 'Ch1' },
                                    { value: 'Ch2', label: 'Ch2' },
                                ]}
                            />
                        </div>
                        <div className="convert-balance-info">Balance 0.000 XXX</div>
                    </div>

                    <div className="status-icon margin-top-icon" onClick={handleConvert} >
                        <img src={ConvertIcon} alt="" />
                    </div>
                    <div className="convert-input-group">
                        <div className="set-price-group">
                            <input type="text" className="set-price-input-convert" placeholder="0.0000"/>
                            <Select
                                onChange={()=>{}}
                                className="set-price-input-select"
                                defaultValue="BNB"
                                options={[
                                    { value: 'BNB', label: 'BNB' },
                                    { value: 'Ch1', label: 'Ch1' },
                                    { value: 'Ch2', label: 'Ch2' },
                                ]}
                            />
                        </div>
                        <div className="convert-balance-info">Balance 0.000 XXX</div>
                    </div>
                </div>

                <div className="convert-listing-modal-buttons">
                    <button className="convert-listing-modal-buttons_convert" onClick={handleExchange}>Approve</button>
                </div>
            </Modal>
        </div>
    )
}

export default MobileHeader;
