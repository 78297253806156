import React, {useEffect, useState} from "react";
import {Modal, Select} from "antd";
import NFT from "../assets/nft/nft_1.svg";

function Sale () {
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [formDetails, setFormDetails] = useState(null);
    const [price, setPrice] = useState(null);
    const [chain, setChain] = useState("BNB");
    const [days, setDays] = useState(7);
    const [category, setCategory] = useState("All");
    const [tokenAddress, setTokenAddress] = useState(null);
    const [tokenId, setTokenId] = useState(null);
    const [description, setDescription] = useState(null);
    const [priceBNB, setPriceBNB] = useState<any>("0000");
    const [priceUSD, setPriceUSD] = useState<any>("0000");


    const onFinish = (...value:any) => {
        value.price = price;
        value.chain = chain;
        value.days = days;
        value.category = category;
        value.tokenAddress = tokenAddress;
        value.tokenId = tokenId;
        value.description = description;
        if (price && chain && days && category && tokenAddress && tokenId && description) {
            setFormDetails(value);
            setApproveModalOpen(true)
        }
    };
    console.log("formDetails",formDetails);

    const handleApprove = () => {
        console.log("Approve",formDetails);
        //TODO set formDetails to backend
        setApproveModalOpen(false);
    }

    const handleCancel = () => {
        setApproveModalOpen(false);
    }

    const selectedPrice = (event:any)=>{
        setPrice(event.target.value)
    };

    const selectedChain = (event:any)=>{
        setChain(event)
    };

    const selectedDays = (days:any)=>{
        setDays(days)
    };

    const selectedCategory = (category:any)=>{
        setCategory(category)
    };

    const handleTokenAddress = (event:any) => {
        let address = event.target.value
        if (address.length >= 16) {
            setTokenAddress(address)
        }
    };

    const handleTokenId = (event:any) => {
        setTokenId(event.target.value)
    };

    const handleDescription = (event:any) => {
        setDescription(event.target.value)
    }

    return (
        <div className="sale">
            <div className="sale-left-side">
                <div className="sale-left-side_title">List for sale</div>
                <div className="add-nft-form">
                    <div className="add-nft-form_item">
                        <div className="add-nft-form_item__title">
                            Set a price
                        </div>
                        <div className="set-price-group">
                            <input type="text" className="set-price-input" placeholder="Amount" onChange={selectedPrice}/>
                            <Select
                                onChange={selectedChain}
                                className="set-price-input-select"
                                defaultValue="BNB"
                                options={[
                                    { value: 'BNB', label: 'BNB' },
                                    { value: 'Ch1', label: 'Ch1' },
                                    { value: 'Ch2', label: 'Ch2' },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="add-nft-form_item">
                        <div className="add-nft-form_item__title">
                            Period
                        </div>
                        <Select
                            onChange={selectedDays}
                            className="period-selector"
                            defaultValue="7 days"
                            options={[
                                { value: '7', label: '7 days' },
                                { value: '14', label: '14 days' },
                                { value: '28', label: '28 days' },
                            ]}
                        />
                    </div>
                    <div className="add-nft-form_item">
                        <div className="add-nft-form_item__title">
                            Category
                        </div>
                        <Select
                            onChange={selectedCategory}
                            className="period-selector"
                            defaultValue="All"
                            options={[
                                { value: 'All', label: 'All' },
                                { value: 'Art', label: 'Art' },
                                { value: 'Gaming', label: 'Gaming' },
                                { value: 'Membership', label: 'Membership' },
                                { value: 'PFPs', label: 'PFPs' },
                                { value: 'Photography', label: 'Photography' },
                                { value: 'Music', label: 'Music' },
                            ]}
                        />
                    </div>
                    <div className="token-info">
                        <div className="add-nft-form_item w-100">
                            <div className="add-nft-form_item__title">
                                Token address
                            </div>
                            <input type="text" className="token-info-input" onInput={handleTokenAddress}/>
                        </div>
                        <div className="add-nft-form_item w-100">
                            <div className="add-nft-form_item__title">
                                Token ID
                            </div>
                            <input type="text" className="token-info-input" onInput={handleTokenId}/>
                        </div>
                    </div>
                    <div className="add-nft-form_item">
                        <div className="add-nft-form_item__title">
                            NFT description
                        </div>
                        <textarea className="nft-description" placeholder="Add a few words about NFT:" onInput={handleDescription}/>
                    </div>
                    <div className="add-nft-form_item">
                        <div className="add-nft-form_item__title">
                            Summary
                        </div>
                        <div className="summary">
                            <div className="summary_top">
                                <div className="summary_top__item">
                                    <span>Listing price</span>
                                    <span>xxx</span>
                                </div>
                                <div className="summary_top__item">
                                    <span>Creator earnings</span>
                                    <span>xxx</span>
                                </div>
                                <div className="summary_top__item">
                                    <span>Service fee</span>
                                    <span>xxx</span>
                                </div>
                            </div>
                            <div className="summary_bottom">
                                <div className="summary_bottom_left">Total potential earnings</div>
                                <div className="summary_bottom_right">
                                    <div className="summary_bottom_right_crypto">{priceBNB.slice(0,1) + "," + priceBNB.slice(1)} BNB</div>
                                    <div className="summary_bottom_right_usd">${priceUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <button onClick={onFinish} className="listing-button">Approve listing</button>
                </div>

            </div>

            <div className="sale-right-side">
                <div className="sale-mob-title">List for sale</div>
                <div className="sales-nft-card">
                    <div className="sales-nft-card_nftimage" >
                        {tokenAddress && tokenId ? <img src={NFT} alt=""/>: ""}
                    </div>
                    <div className="sales-nft-card_footer">
                        <div className="sales-nft-card_footer_top">
                            <span className="sales-nft-card_footer_top_price1">xxx</span>
                            <span className="sales-nft-card_footer_top_price2">xxx</span>
                        </div>
                        <div className="sales-nft-card_footer_bottom">
                            {priceBNB.slice(0,1) + "." + priceBNB.slice(1)} BNB
                        </div>
                    </div>
                </div>
                <button className="accept-offer">
                    Accept offer {priceBNB.slice(0,1) + "." + priceBNB.slice(1)} BNB
                </button>
            </div>

            <Modal
                centered
                width={609}
                open={approveModalOpen}
                onCancel={() => setApproveModalOpen(false)}
                footer={null}
                className="approve-popup"
            >
                <div className="approve-listing-modal-title">Approve listing</div>
                <div className="approve-listing-modal-content">
                    <div className="approve-listing-modal-content_left">
                        <img src={NFT} alt="" className="approve-listing-modal-content_left_nft"/>
                        <div className="approve-listing-modal-content_left_nft-info">
                            <div className="approve-listing-modal-content_left_nft-info_top">
                                <div className="approve-listing-modal-content_left_nft-info_top_price1">xxx</div>
                                <div className="approve-listing-modal-content_left_nft-info_top_price2">xxx</div>
                            </div>
                            <div className="approve-listing-modal-content_left_nft-info_chain">
                                Chain: {"xxx"}
                            </div>

                        </div>
                    </div>

                    <div className="approve-listing-modal-content_right">
                        <div className="approve-listing-modal-content_right_price-bnb">
                            {priceBNB.slice(0,1) + "," + priceBNB.slice(1)} BNB
                        </div>
                        <div className="approve-listing-modal-content_right_price-usd">
                            ${priceUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </div>

                    </div>

                </div>
                <div className="approve-listing-modal-buttons">
                    <button className="approve-listing-modal-buttons_cancel" onClick={handleCancel}>Cancel</button>
                    <button className="approve-listing-modal-buttons_approve" onClick={handleApprove}>Approve</button>
                </div>
            </Modal>

        </div>
    )
};

export default Sale;